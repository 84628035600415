export default {
  installation_typologies: {
    type_1: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
      {
        value: "sistema_diretto",
        text: "Sistema diretto",
      },
      {
        value: "sistema_aperto_a_spray",
        text: "Sistema aperto a spray",
      },
      {
        value: "sistema_canalizzato",
        text: "Sistema canalizzato",
      },
      {
        value: "sistema_di_nebulizzazione_a_sfiato",
        text: "Sistema di nebulizzazione a sfiato",
      },
      {
        value: "sistema_indiretto_chiuso",
        text: "Sistema indiretto chiuso",
      },
    ],

    type_2: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
      {
        value: "sistema_indiretto_con_lo_sfiato",
        text: "Sistema indiretto con lo sfiato",
      },
      {
        value: "sistema_indiretto_chiuso_con_lo_sfiato",
        text: "sistema indiretto chiuso con lo sfiato",
      },
      {
        value: "sistema_indiretto_doppio",
        text: "Sistema indiretto doppio",
      },
      {
        value: "sistema_indiretto_ad_alta_pressione",
        text: "Sistema indiretto ad alta pressione",
      },
    ],

    all: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
      {
        value: "sistema_diretto",
        text: "Sistema diretto",
      },
      {
        value: "sistema_aperto_a_spray",
        text: "Sistema aperto a spray",
      },
      {
        value: "sistema_canalizzato",
        text: "Sistema canalizzato",
      },
      {
        value: "sistema_di_nebulizzazione_a_sfiato",
        text: "Sistema di nebulizzazione a sfiato",
      },
      {
        value: "sistema_indiretto_chiuso",
        text: "Sistema indiretto chiuso",
      },
      {
        value: "sistema_indiretto_con_lo_sfiato",
        text: "Sistema indiretto con lo sfiato",
      },
      {
        value: "sistema_indiretto_chiuso_con_lo_sfiato",
        text: "sistema indiretto chiuso con lo sfiato",
      },
      {
        value: "sistema_indiretto_doppio",
        text: "Sistema indiretto doppio",
      },
      {
        value: "sistema_indiretto_ad_alta_pressione",
        text: "Sistema indiretto ad alta pressione",
      },
    ],
  },
};
